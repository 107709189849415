<template>
  <div>
    <TheTeCommonHeader />
    <main class="te-diagnoses-bg pt-[4.375rem]">
      <slot />
    </main>
    <TheTeCommonFooter>
      <template #top-menu>
        <ul class="te-footer-top mb-2 flex flex-wrap">
          <li class="mb-2 mr-6">
            <a
              href="/course"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >コース紹介</a>
          </li><li class="mb-2 mr-6">
            <a
              href="/price"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >料金プラン</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
              href="https://support.techacademy.jp/hc/ja"
            >よくあるご質問</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              href="/blog"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >受講生インタビュー</a>
          </li>
          <li class="mb-2 mr-6">
            <a
              href="/job"
              class="block p-0 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >メンター募集</a>
          </li>
          <li class="mb-2">
            <a
              href="/magazine/"
              target="_blank"
              class="block p-0 pr-3 text-[0.875rem] text-co-gray-400 hover:text-co-gray-400"
            >マガジン<i
              class="fa fa-external-link pl-2"
              aria-hidden="true"
            />
            </a>
          </li>
        </ul>
      </template>
    </TheTeCommonFooter>
  </div>
</template>

<script setup lang="ts">
import { useUser } from '~/store/user'
import TheTeCommonHeader from '~/components/Te/TeCommon/Header/TheTeCommonHeader/TheTeCommonHeader.vue'
import TheTeCommonFooter from '~/components/Te/TeCommon/Footer/TheTeCommonFooter/TheTeCommonFooter.vue'

useHead({
  htmlAttrs: {
    'data-theme': 'techacademy',
  },
})

const user = useUser()

onMounted(async () => {
  await user.getCurrentUser()
})
</script>

<style scoped>
.te-diagnoses-bg {
  background-color: #49b9f4;
  background-image: url('@/assets/images/Te/TeDiagnoses/bg-diagnoses.png');
  background-position: 0 4.375rem;
}
</style>
